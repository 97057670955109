import React, { useState, useEffect } from "react";
import "./App.css";
import { Telegram, Twitter, Raydium, Uniswap, DexTools } from "./arts/Icons";
import { useSpring, animated } from "@react-spring/web";
import backgroundMobile from "./arts/background.png";
import backgroundDesktop from "./arts/backgroundcat.svg";
import moon from "./arts/moon.png";
import bong from "./arts/bong.webp";
import stand from "./arts/standing.png";
import scratch from "./arts/scratch.png";
import candle from "./arts/candle.png";
import logo from "./arts/flufflog.png";
import uplogo from "./arts/logo.png";
import cry from "./arts/cry.png";
import hug from "./arts/hug.png";

import {
  DeckWrapper,
  HeroBtn,
  HeroDescription,
  HeroPicture,
  HeroQuestion,
  HeroSection,
  HeroTitle,
  HeroTitleMobile,
  TextWrapper,
} from "./Style/Hero.styled";
import {
  MenuBtn,
  MenuItem,
  MenuList,
  NavMenu,
  BurgerItem,
  MenuItemBig,
  BurgerList,
} from "./Style/Menu.styled";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update `isMobile` on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Styles for various elements
  const containerStyle = {
    width: "100vw",
    minHeight: "100vh",
    position: "relative" as const,
    backgroundColor: "#fff4e2",
  };

  const logoStyleRot = {
    transition: "opacity 1s ease-in-out", // Adjust timing and easing here
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as const,
    padding: "5vh 2vw",
  };

  const logoStyle = {
    fontSize: isMobile ? "3rem" : "5rem",
    color: "#333",
    fontFamily: "Dyna",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontSize: isMobile ? "1rem" : "1.5rem",
    color: "#666",
    fontFamily: "Cloud2",
    marginBottom: "20px",
  };

  const buttonContainerStyle = {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginTop: "20px",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px", // Set a fixed width for uniform size
    height: "50px", // Set a fixed height for uniform size
    backgroundColor: "#fff",
    borderRadius: "50%", // Circle shape
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textDecoration: "none" as const,
    color: "#333",
  };

  const sectionStyle = {
    padding: "10vh 5vw",
    backgroundColor: "#fff",
    textAlign: "center" as const,
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    margin: "20px 0",
  };

  const footerStyle = {
    backgroundColor: "#E4B25F",
    padding: "5vh 2vw",
    textAlign: "center" as const,
    color: "#fff",
  };

  const wobbleAnimation = useSpring({
    loop: true, // Ensures the animation keeps looping
    from: { rotate: 0 },
    to: [
      { rotate: -10 }, // Rotate slightly left
      { rotate: 10 }, // Rotate slightly right
      { rotate: 0 }, // Reset to the center
    ],
    config: { tension: 180, friction: 12 }, // Adjust for smoother wobble
  });

  return (
    <div className="background">
      <div className="hero-section">
        {/* Images around the hero section
        //<img src={moon} alt="Moon" className="hero-image-left wobble-effect1" /> */}

        <img
          src={moon}
          alt="Bong"
          className="hero-image-right-top wobble-effect1 "
        />

        <img
          src={scratch}
          alt="Candle"
          className="hero-image-right-bottom wobbleRot "
        />

        {/* Hero content */}
        {isMobile ? (
          <HeroTitleMobile className="hero-title">$FLUFNUT</HeroTitleMobile>
        ) : (
          <HeroTitle className="hero-title">$FLUFNUT</HeroTitle>
        )}

        <HeroDescription className="hero-description">
          Mr. FlufferNutter the Business Cat
        </HeroDescription>
        <div className="hero-menu">
          <MenuItem>
            <a
              href="https://t.me/FlufferNutter"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Telegram"
            >
              <Telegram />
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="https://x.com/fluffercat69"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Twitter page"
            >
              <Twitter />
            </a>
          </MenuItem>
          <MenuItemBig>
            <a
              href="https://app.uniswap.org/swap?outputCurrency=0x42069401135E0b750E737d1C0C0DBa8bE273a41e&chain=ethereum"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Uniswap"
            >
              <Uniswap />
            </a>
          </MenuItemBig>
          <MenuItemBig>
            <a
              href="https://app.uniswap.org/swap?outputCurrency=0x42069401135E0b750E737d1C0C0DBa8bE273a41e&chain=ethereum"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Uniswap"
            >
              <Raydium />
            </a>
          </MenuItemBig>
          <MenuItemBig>
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0x63e260a5c670afd752a0f3c7a2ec9b7c4b1fc6df?t=1730148125649"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Uniswap"
            >
              <DexTools />
            </a>
          </MenuItemBig>
        </div>
      </div>

      <section className="section">
        <img src={logo} alt="FlufferNutter Logo" className="logo" />
        <div style={{ display: "flex", flexDirection: "column", width: "60%" }}>
          <h2>The Business Cat Has Arrived!</h2>
          <p>
            Climbing the corporate ladder one paw at a time, Mr. FlufferNutter
            isn't just your average office pet. He’s the whiskered tycoon behind
            the newest craze in the crypto market. With a keen nose for
            profitable treats and a purr that can smooth even the most ruffled
            feathers, Mr. FlufferNutter brings new meaning to "feline good"
            about your investments.
          </p>
          <p>CA: TBA</p>
        </div>
      </section>

      <section className="section2">
        <img src={hug} alt="Hug" className="hero-image-left wobble-effect" />
        <img src={cry} alt="Cry" className="hero-image-right wobble-effect1" />
        <h2>Join a community of like-minded cats</h2>
        <p>Have some nuts and enter the cult</p>
        <div className="hero-menu">
          <MenuItem>
            <a
              href="https://t.me/FlufferNutter"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Telegram"
            >
              <Telegram />
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="https://x.com/fluffercat69"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="a link to the Twitter page"
            >
              <Twitter />
            </a>
          </MenuItem>
        </div>
      </section>
    </div>
  );
}

export default App;
